<template>
  <div class="uservoice-wrapper">
    <div
      class="main"
      @click="playVoice"
      :style="{ width: duration * 20 + 'px' }"
    >
      <img
        :class="['voice-img', { playing: isPlay }]"
        src="../../assets/img/right-voice.png"
        alt=""
      />
      <span>{{ duration }}''</span>
    </div>
    <audio ref="audio"></audio>
  </div>
</template>

<script>
import { HtmlFilter } from "../../utils/index";
import { dataURLtoBlob } from "../../utils/index";
// import WeiXin from '../../assets/js/weixin';

export default {
  props: ["voice"],
  data() {
    return {
      recorder: null,
      duration: 0,
      isPlay: false,
    };
  },
  mounted() {
    let blob = JSON.parse(HtmlFilter(this.voice));
    this.duration = blob.duration;
    this.recorder = dataURLtoBlob(blob.recorder);
  },
  methods: {
    playVoice() {
      let audioStream = URL.createObjectURL(this.recorder);
      let self = this;
      let audio = this.$refs.audio;
      audio.src = audioStream;
      audio.load();
      audio.play();
      
      // e.wx.config({
      //   debug: false, // 这里为false
      //   appId: "wx0ecf939d6cad3164", // 这里的appid 是必填的，可以到官网看源代码得到一个appid

      //   // 以下随意填写即可
      //   timestamp: new Date().getTime(),
      //   nonceStr: "",
      //   signature: "",
      //   jsApiList: ["checkJsApi"],
      // });

      // wx.ready(function() {
      //   audio.play();
      // });
      audio.onplaying = () => {
        self.isPlay = true;
      };
      audio.onended = () => {
        self.isPlay = false;
      };
    },
  },
};
</script>

<style lang="less" scoped>
@imgSize: 8vw;
.uservoice-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 6.4vw;
  margin-right: 2vw;

  .main {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    background-color: #4da495;
    border-radius: 2.1333vw;
    color: white;
    height: 13.3333vw;
    margin-right: 3.6vw;
    box-sizing: border-box;
    min-width: 20vw;
    max-width: 66.6667vw;
    padding-right: 3vw;

    > span {
      margin-right: 1vw;
    }

    .playing {
      animation: play 2s linear infinite;
    }
  }

  .voice-img {
    width: @imgSize;
    height: @imgSize;
  }
}

@keyframes play {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}
</style>
